(function ($) {

    // jQuery test
    if (window.jQuery) {
        console.log('jQuery is working...');
    }

    // ajax variables test
    console.log("ajax.url:", ajax.url);
    console.log("ajax.nonce:", ajax.nonce);

    /**
     * Odpowiada za zatrzymanie odtwarzacza youtube.
     * 
     * @version 1.0.0
     */
    $('.js-stopYouTube').click(function() {
        $('.js-youtube-embed').each(function (item, name){
            name.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
        });
    });
    
    /**
     * Odpowiada za wyświetlenie border po najechaniu na artykuł
     * 
     * @version 1.2.0
     */
    $('.js-home-articles-link').hover(function () {
        $('.home-articles__image-frame', this).addClass('home-articles__image-frame--bordered');
    }, function () {
        $('.home-articles__image-frame', this).removeClass('home-articles__image-frame--bordered');
    });

    $('.js-news-articles-link').hover(function () {
        $('.news__articles-image-frame', this).addClass('news__articles-image-frame--bordered');
    }, function () {
        $('.news__articles-image-frame', this).removeClass('news__articles-image-frame--bordered');
    });

    $('.js-news-articles-second-link').hover(function () {
        $('.news__articles-second-image-frame', this).addClass('news__articles-second-image-frame--bordered');
    }, function () {
        $('.news__articles-second-image-frame', this).removeClass('news__articles-second-image-frame--bordered');
    });

    $('.js-article-more-link').hover(function () {
        $('.article__more-image-frame', this).addClass('article__more-image-frame--bordered');
    }, function () {
        $('.article__more-image-frame', this).removeClass('article__more-image-frame--bordered');
    });

    /**
     * Funkcja odpowiada za ostylowanie widoku artykułu (zmiana koloru tła).
     * 
     * @version 1.0.0
     */
    var $text = $('.js-article-text');
    if($text.length > 0) {
        var $image = $('.js-article-image');
        var $textEnd = $('.js-article-text-end');

        var scroll_image = ($image.offset().top - $image.offsetParent().offset().top) + ($image.height()/2);
        var scroll_text_bottom = $textEnd.offset().top - $textEnd.offsetParent().offset().top;

        var percent = ((100/1)*(scroll_image)/scroll_text_bottom);

        $('.js-article-content').css('background', 'linear-gradient(180deg, #232323 0%, #232323 ' + percent + '%, #fff ' + percent + '%, #fff 100%)');

        console.log('.js-article-text', $text);
        console.log('.js-article-image', $image);
        console.log('scroll_image', scroll_image);
        console.log('scroll_image', scroll_text_bottom);
        console.log('percent', percent);
    }

    /**
     * Smooth scroll
     * 
     * @link https://css-tricks.com/snippets/jquery/smooth-scrolling/ Source.
     * @version 1.0.0
     */
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .not('[href*="#pills-offer"]')
    .not('[href*="#gallery"]')
    .not('[href*="#collapse-person-description"]')
    .click(function(event) {
    if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
        && 
        location.hostname == this.hostname
    ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 1000, function() {
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) {
                    return false;
                } else {
                    $target.attr('tabindex','-1');
                    $target.focus();
                };
            });
        }
    }
    });

    /**
     * Funkcja odpowiadająca za działanie kart z osobami.
     * 
     * @version 1.0.0
     */
    $('.js-person-card').hover(function () {
        $(this).addClass('about__person-card--gold');
        $('.js-person-card-image', this).addClass('about__person-card-image--hide');
        $('.js-person-card-text', this).removeClass('about__person-card-text--hide');
        $('.js-person-card-name', this).addClass('about__person-card-name--black');
        $('.js-person-card-position', this).addClass('about__person-card-position--black');
    }, function () {
        $(this).removeClass('about__person-card--gold');
        $('.js-person-card-image', this).removeClass('about__person-card-image--hide');
        $('.js-person-card-text', this).addClass('about__person-card-text--hide');
        $('.js-person-card-name', this).removeClass('about__person-card-name--black');
        $('.js-person-card-position', this).removeClass('about__person-card-position--black');
    });
    
})(jQuery);