/**
 * Funkcja odpowiada za wyswietlenie komunikatu o ciasteczkach.
 * 
 * @version 1.0.0
 */
(function ($) {

    if (typeof Cookies.get('lavida') === 'undefined'){
        $('.js-cookie-info').show();
        $('.js-accept-cookie').on('click', function (e) {
            e.preventDefault();
            Cookies.set('lavida', 'Cookies!_Om_nom_nom_nom!_*Cookies_Monster_eats_all_cookies*~AlpakaPozdrawia', {
                expires : 31,
                secure  : false
            });
            $('.js-cookie-info').hide();
        })
    }

})(jQuery);
