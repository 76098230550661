/**
 * Inicjalizacja sliderów Slick
 */
(function ($) {
    
    /**
     * Home - Slider główny
     */
    var $homeSlider = $('.js-home-slider');
    if ($homeSlider.length > 0) {
        $homeSlider.slick({
            fade: true
        });
        $homeSlider.on('afterChange', function(event, slick, currentSlide){
            var slideCount = slick.slideCount;
            var slideUp = currentSlide + 2;
            var slideDown = currentSlide + 1;

            if (slideUp > slideCount) {
                slideUp = 1;
            }

            var $nextSlide = $('#js-next-slide');
            var $currentSlide = $('#js-current-slide');

            $nextSlide.html('0' + slideUp);
            $currentSlide.html('0' + slideDown);
        });
    }
    
    /**
     * Home - Drugi slider
     */
    var $homeSecondSlider = $('.js-home-second-slider');
    if ($homeSecondSlider.length > 0) {
        $homeSecondSlider.slick({
            fade: true,
            dots: true,
            arrows: false,
            dotsClass: 'slick-dots home-second-slider__dots',
        });
    }

    /**
     * Home - Slider z ofertą - zabiegi dla niej
     */
    var $offerSliderWoman = $('.js-home-offer-slider-woman');
    if ($offerSliderWoman.length > 0) {
        $offerSliderWoman.on('init', function(event, slick){
            var rightImageUrl = $('.js-home-offer-slider-woman-slide').eq(2).children('img').attr('src');
            $('.js-home-offer-slider-background-image').attr('src', rightImageUrl);
        });
        $offerSliderWoman.slick({
            infinite: false,
            slidesToShow: offerSlidesToShow,
            slidesToScroll: 1,
            dots: true,
            adaptiveHeight: true,
            appendDots: '.js-home-offer-slider-woman-dots',
            edgeFriction: 0
        });
        $offerSliderWoman.on('afterChange', function(event, slick, currentSlide){
            var rightImageUrl = $(this).slick('getSlick').$slides.eq((currentSlide+2)).children('img').attr('src');
            var image = $('.js-home-offer-slider-background-image');
            image.fadeOut(200, function() {
                image.attr('src', rightImageUrl);
            }).fadeIn(200);
        });
        // Scrollbar
        dragable(
            '.js-home-offer-slider-woman-scrollbar-track',
            '.js-home-offer-slider-woman-scrollbar-thumb',
            '.js-home-offer-slider-woman-container',
            $offerSliderWoman
        );
    }

    /**
     * Home - Slider z ofertą - zabiegi dla niego
     */
    var $offerSliderMan = $('.js-home-offer-slider-man');
    if ($offerSliderMan.length > 0) {
        $offerSliderMan.slick({
            infinite: false,
            slidesToShow: offerSlidesToShow,
            slidesToScroll: 1,
            dots: true,
            adaptiveHeight: true,
            appendDots: '.js-home-offer-slider-man-dots',
            edgeFriction: 0
        });
        $offerSliderMan.on('afterChange', function(event, slick, currentSlide){
            var rightImageUrl = $(this).slick('getSlick').$slides.eq((currentSlide+2)).children('img').attr('src');
            var image = $('.js-home-offer-slider-background-image');
            image.fadeOut(200, function() {
                image.attr('src', rightImageUrl);
            }).fadeIn(200);
        });
    }

    /**
     * Kontakt - Slider z personelem
     */
    $sliderContact = $('.js-persons-slider-contact');
    if ($sliderContact.length > 0) {
        $sliderContact.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
        });
    }

    /**
     * Aktualności - Slider z newsami
     */
    $slideNews = $('.js-news-slider');
    if ($slideNews.length > 0) {
        $slideNews.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            appendArrows: '.js-slider-navigation'
        });
        $slideNews.on('afterChange', function(event, slick, currentSlide){
            var slideDown = currentSlide + 1;
            var slideMax = slick.slideCount;
            $('.js-news-slide-number').html(slideDown + '/' + slideMax);
        });
    }

    /**
     * Odświeżenie slidera na stronie głównej przy przejściu tabpill od Bootstrap.
     * Naprawia wyświetlanie slidera oraz scrolla.
     * 
     * @version 1.3.0
     */
    $('a[data-toggle="pill"]').on('shown.bs.tab', function(e) {
        if ($offerSliderWoman.length > 0 || $offerSliderMan.length > 0) {
            e.target
            e.relatedTarget
            $offerSliderWoman.off('.mda');
            $offerSliderMan.off('.mda');
    
            $offerSliderWoman.slick('setPosition');
            $offerSliderMan.slick('setPosition');
    
            dragable(
                '.js-home-offer-slider-woman-scrollbar-track',
                '.js-home-offer-slider-woman-scrollbar-thumb',
                '.js-home-offer-slider-woman-container',
                $offerSliderWoman
            );
            dragable(
                '.js-home-offer-slider-man-scrollbar-track',
                '.js-home-offer-slider-man-scrollbar-thumb',
                '.js-home-offer-slider-man-container',
                $offerSliderMan
            );
        }
    });

    /**
     * Home - Funkcja odpowiada za scrollbar pod sliderem.
     * 
     * @version 1.0.0
     */
    function dragable(trackEl, thumbEl, container, slickdiv) {
        var $track = $(trackEl);
        var $thumb = $(thumbEl);
        var isDragging = false;
        var trackOffset = $track.offset().left;
        var slides = $(container).find('.slick-dots li');
        var slidesQty = slides.length;
        var trackWidth = $track.width();
        var thumbWidth = $track.width()/slidesQty;
        $thumb.css('width', thumbWidth);
        var $slickTrack = $(container).find('.slick-track');
        var slickTrackWidth = slidesQty* $(container).find('.slick-slide').width();
        var slickMoveMultiplier = slickTrackWidth/trackWidth;
        var maxRelX = trackWidth-thumbWidth;
        var thumbCenter = (thumbWidth/2);
        var dots = [];

        $.each(slides, function(index){
            var _ = $(this);
            var center = (_.offset().left - trackOffset) + (thumbWidth/2);
            dots.push({'index': index, 'center': center})
        });

        $thumb.on('mousedown', function(e){
            if(e.which == 1){
                e.preventDefault();
                isDragging = true;
                $thumb.addClass('is-dragging');
                return false;
            }
        });
    
        $(window).on('mouseup', function(e){
            if(isDragging && e.which == 1){
                e.preventDefault();
                isDragging = false;
                $thumb.removeClass('is-dragging');
                takeMeToMyGirl($thumb, thumbWidth, thumbCenter, dots, slickdiv);
            }
        });
    
        $(window).on('mousemove', function(e){
    
            if(isDragging){
                var relX = e.pageX - trackOffset - (thumbWidth/2);
                var thumbOffset = $thumb.offset().left;
                if(relX >= 0 && relX <= maxRelX){
                    $thumb.css('transform', 'translateX(' + relX + 'px)');
                    $slickTrack.css('transform', 'translate3d(' + -relX*slickMoveMultiplier + 'px, 0px, 0px)');
                } else if(relX < 0){
                    $thumb.css('transform', 'translateX(' + 0 + 'px)');
                    $slickTrack.css('transform', 'translate3d(' + 0 + 'px, 0px, 0px)');
                } else if (relX > maxRelX){
                    $thumb.css('transform', 'translateX(' + maxRelX + 'px)');
                    $slickTrack.css('transform', 'translate3d(' + -maxRelX*slickMoveMultiplier + 'px, 0px, 0px)');
                }
                thumbCenter = (thumbOffset - trackOffset) + (thumbWidth/2);
            }
    
        });

        slickdiv.on('beforeChange.mda', function(event, slick, currentSlide, nextSlide){
            $thumb.css('transform', 'translateX(' + (dots[nextSlide].center - thumbWidth/2) + 'px)');
        });
    }

    function takeMeToMyGirl($me, myWidth, myPosition, girls, slickdiv){
        var tmpDiff = 9999;
        var myLoverIndex = false;

        $.each(girls, function(key, value){
            var diff = Math.abs(myPosition - value.center);

            if( diff < tmpDiff){
                myLoverIndex = key;
                tmpDiff = diff;
            }
        });
        slickdiv.slick('slickGoTo', myLoverIndex);
    }
    
})(jQuery);